import { FC } from "react";
import css from "./UserCards.module.css";
import { ReactComponent as WithdrawIcon } from "./img/withdraw.svg";
import { ReactComponent as TopUpIcon } from "./img/top-up-icon.svg";
import ButtonWithIcon from "../UI/Buttons/ButtonWithIcon/ButtonWithIcon";

interface UserCardProps {
  bonuse: string;
  balance: string;
}

const UserCards: FC<UserCardProps> = ({ bonuse, balance }) => {
  return (
    <div className={css.wrapper}>
      <p className={css.value}>{bonuse}</p>
      <p className={css.description}>БОНУСЫ</p>
      <div className={css.balanceWrapper}>
        <div>
          <p className={css.value}>{balance}</p>
          <p className={css.description}>БАЛАНС</p>
        </div>
        <div className={css.buttonWrapper}>
          <ButtonWithIcon Icon={TopUpIcon} value={"ПОПОЛНИТЬ"} />
          <div className={css.line}></div>
          <ButtonWithIcon Icon={WithdrawIcon} value={"ВЫВЕСТИ"} />
        </div>
      </div>
    </div>
  );
};

export default UserCards;
