import React, { FC } from "react";
import css from "./header.module.css";

interface HeaderProps {
  amount: string;
}

const Header: FC<HeaderProps> = ({ amount }) => {
  return (
    <header className={css.header}>
      <div className={css.textWrapper}>
        <p className={css.title}>НОМЕР ЗАКАЗА</p>
        <p className={css.info}>#1337</p>
      </div>
      <div className={css.textWrapper}>
        <p className={css.title}>ОПЛАТА</p>
        <p className={css.info}>{amount} USDT</p>
      </div>
    </header>
  );
};

export default Header;
