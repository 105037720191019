import { FC, useEffect } from "react";
import css from "./DetailScreen.module.css";
import cn from "classnames";

import TradeCard from "../../../../components/TradeCard/TradeCard";
import BuyBtn from "../../../../components/UI/Buttons/BuyBtn/BuyBtn";
import BackButton from "../../../../components/UI/Buttons/BackButton/BackButton";
import { getCookie } from "../../../../helpers/cockieHelper";
import { api } from "../../../../api/api";

interface TradeCardProps {
  id: number;
  name: string;
  color: string;
  bothCount: number;
  setMemeScreen: React.Dispatch<React.SetStateAction<string>>;
  setAuthorizedScreen: React.Dispatch<React.SetStateAction<string>>;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  setFooterVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

type ColorKeys = "blue" | "green" | "purple" | "red";

const getCoast = {
  blue: "40",
  green: "200",
  purple: "1000",
  red: "5000",
};

const mockDescription =
  "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter.";
const DetailScreen: FC<TradeCardProps> = ({
  id,
  name,
  color,
  bothCount,
  setMemeScreen,
  setAuthorizedScreen,
  setAmount,
  setFooterVisible,
}) => {
  const colorName: ColorKeys = color as ColorKeys;

  const makeInvest = async () => {
    setAmount(name);
    setFooterVisible(false);
    setAuthorizedScreen("ecvaring");
  };

  useEffect(() => {}, []);

  return (
    <div
      className={cn(css.wrapper, {
        [css.wrapperBgBlue]: color === "blue",
        [css.wrapperBgRed]: color === "red",
        [css.wrapperBgPurpule]: color === "purple",
        [css.wrapperBgGreen]: color === "green",
      })}
    >
      <div className={css.backBtnWrapper}>
        <BackButton goBack={() => setMemeScreen("memetrade")} />
      </div>

      <TradeCard
        name={name}
        color={color}
        bothCount={bothCount}
        setMemeScreen={() => {}}
        description={mockDescription}
        size="big"
      />

      <div className={css.slotsWrapper}>
        {[...Array(5 - bothCount)].map((_, index) => (
          <BuyBtn
            key={index}
            color={"disable"}
            description={"СЛОТ КУПЛЕН"}
            coast={getCoast[colorName]}
            onClick={() => {
              alert("Слот куплен");
            }}
          />
        ))}
        {[...Array(bothCount)].map((_, index) => (
          <BuyBtn
            key={index}
            color={color}
            description={"КУПИТЬ СЛОТ"}
            coast={getCoast[colorName]}
            onClick={() => {
              makeInvest();
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default DetailScreen;
