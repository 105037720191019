import { FC } from "react";
import qr from "./qr.png";
import css from "./main.module.css";
import Button from "../button/button.component";

const Main: FC = () => {
  const copyTextToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("Текст успешно скопирован в буфер обмена!");
    } catch (err) {
      console.error("Ошибка:", err);
    }
  };
  return (
    <div className={css.wrapper}>
      <div className={css.qrWrapper}>
        <div className={css.label}>
          <span>USDT TRC-20</span>
        </div>
        <img src={qr} className={css.qr} alt="qr код" />
      </div>
      <div className={css.instructionWrapper}>
        <span>отсканируйте QR код</span>
        <div className={css.blockOr}>
          <div className={css.line} />
          <span>или</span>
          <div className={css.line} />
        </div>
        <span>переведите USDT TRC-20 по адресу</span>
        <Button
          onClick={() => copyTextToClipboard("TRsJTSAJzvzT3ReBoZGhtKDesP938V3q7t")}
          copy={true}
          value="TRsJTSAJzvzT3ReBoZGhtKDesP938V3q7t"
        />
      </div>
    </div>
  );
};
export default Main;
