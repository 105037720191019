import { FC, useState } from "react";
import Footer from "../footer/footer.component";
import Header from "../header/header.component";
import Main from "../main/main.component";
import Overlay from "../overlay/overlay.component";
import css from "./main.page.module.css";

interface EcvaringProps {
  wallet: string;
  amount: string;
  setAuthorizedScreen: React.Dispatch<React.SetStateAction<string>>;
}
const MainPage: FC<EcvaringProps> = ({
  wallet,
  amount,
  setAuthorizedScreen,
}) => {
  const [isOverlayVisible, setOverlay] = useState<boolean>(false);
  return (
    <div className={css.App}>
      <Header amount={amount} />
      <Main />
      <Footer
        setOverlay={setOverlay}
        amount={amount}
        setAuthorizedScreen={setAuthorizedScreen}
      />
      <Overlay isVisible={isOverlayVisible} />
    </div>
  );
};

export default MainPage;
