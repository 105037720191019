import { FC } from "react";
import css from "./CopyButton.module.css";
import { ReactComponent as Copy } from "./img/copy-icon.svg";

interface CopyButtonProps {
  value: string;
}

const CopyButton: FC<CopyButtonProps> = ({ value }) => {
  const copyTextToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("Текст успешно скопирован в буфер обмена!");
    } catch (err) {
      console.error("Ошибка:", err);
    }
  };
  return (
    <div className={css.wrapper} onClick={() => copyTextToClipboard(value)}>
      <p className={css.text}>{value}</p>
      <Copy />
    </div>
  );
};
export default CopyButton;
