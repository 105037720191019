import { FC } from "react";
import cn from "classnames";
import css from "./BuyBtn.module.css";

interface BuyBtnProps {
  color: string;
  description: string;
  coast: string;
  onClick: () => void;
}

const BuyBtn: FC<BuyBtnProps> = ({ color, description, coast, onClick }) => {
  return (
    <div
      className={cn(css.wrapper, {
        [css.wrapperBlue]: color === "blue",
        [css.wrapperPurple]: color === "purple",
        [css.wrapperRed]: color === "red",
        [css.wrapperGreen]: color === "green",
        [css.wrapperDisable]: color === "disable",
      })}
      onClick={onClick}
    >
      <p className={css.description}>{description}</p>
      <p className={cn({ [css.coast]: color !== "disable" })}>${coast}</p>
    </div>
  );
};
export default BuyBtn;
