import { FC, useEffect, useState } from "react";
import TradeCard from "../../components/TradeCard/TradeCard";
import DetailScreen from "./components/DetailScreen/DetailScreen";
import { api } from "../../api/api";
import { getCookie } from "../../helpers/cockieHelper";

interface Product {
  id: number;
  name: string;
  price: string;
  quantity: number;
  purchased: number;
}

const getColorByName = (name: string): string => {
  switch (name) {
    case "40":
      return "blue";
    case "200":
      return "green";
    case "1000":
      return "purple";
    case "5000":
      return "red";
    default:
      return "blue";
  }
};

const cardsArrMock: Product[] = [
  { id: 1, name: "40", price: "40.00", quantity: 5, purchased: 1 },
];

interface MemtradeProps {
  setAuthorizedScreen: React.Dispatch<React.SetStateAction<string>>;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  setFooterVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Memtrade: FC<MemtradeProps> = ({
  setAuthorizedScreen,
  setAmount,
  setFooterVisible,
}) => {
  const [memeScreen, setMemeScreen] = useState<string>("memetrade");
  const [cardsArr, setCardsArr] = useState(cardsArrMock);
  const [detailedData, setDetailedData] = useState({
    id: 1,
    name: "40",
    color: getColorByName("40"),
    bothCount: 1,
  });

  const updateDetailedData = (newData: Partial<typeof detailedData>) => {
    setDetailedData((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  useEffect(() => {
    const getCards = async () => {
      try {
        const response = await api.get("slots/list/", {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `, // Токен из куки
            "Content-Type": "application/json",
          },
        });
        setCardsArr(response.data);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };

    getCards();
  }, []);

  const memeTradeRender = () => {
    switch (memeScreen) {
      case "memetrade":
        return (
          <div className="pt-16 max-w-xs w-full mx-auto pb-8">
            <h1 className="text-custom-large tracking-custom-spaced">
              МЕМТРЕЙД
            </h1>
            <div className="flex flex-col gap-4 mt-4 pb-22 mb-24 ">
              {cardsArr.map((item) => {
                return (
                  <TradeCard
                    name={item.name}
                    color={getColorByName(item.name)}
                    bothCount={5 - item.purchased}
                    setMemeScreen={() => {
                      updateDetailedData({
                        name: item.name,
                        color: getColorByName(item.name),
                        bothCount: 5 - item.purchased,
                        id: item.id,
                      });
                      setMemeScreen("detailed");
                    }}
                    size="smal"
                  />
                );
              })}
            </div>
          </div>
        );
      case "detailed":
        return (
          <DetailScreen
            id={detailedData.id}
            name={detailedData.name}
            color={detailedData.color}
            bothCount={detailedData.bothCount}
            setMemeScreen={setMemeScreen}
            setAuthorizedScreen={setAuthorizedScreen}
            setAmount={setAmount}
            setFooterVisible={setFooterVisible}
          />
        );
    }
  };
  return <>{memeTradeRender()}</>;
};

export default Memtrade;
