import { Dispatch, FC, useState } from "react";
import { ReactComponent as Bascet } from "./icons/bascet.svg";
import { ReactComponent as Comunity } from "./icons/comunity.svg";
import { ReactComponent as Controler } from "./icons/controler.svg";
import { ReactComponent as Home } from "./icons/home.svg";
import { ReactComponent as Profile } from "./icons/profile.svg";

const menuItems = [
  { icon: <Home />, label: "ГЛАВНАЯ", id: "home" },
  { icon: <Bascet />, label: "МЕМТРЕЙД", id: "memetrade" },
  { icon: <Controler />, label: "ИГРА", id: "game" },
  { icon: <Comunity />, label: "ПАРТНЕРКА", id: "referal" },
  { icon: <Profile />, label: "ПРОФИЛЬ", id: "profile" },
];
interface FooterProps {
  setAuthorizedScreen: Dispatch<React.SetStateAction<string>>;

  setActiveItem: Dispatch<React.SetStateAction<string>>;
  activeItem: string;
}
const Footer: FC<FooterProps> = ({
  setAuthorizedScreen,
  setActiveItem,
  activeItem,
}) => {
  const handleClick = (id: string) => {
    setActiveItem(id);
    setAuthorizedScreen(id);
  };
  return (
    <div className="w-full h-[74px] bg-custom-black fixed bottom-0 left-0 flex justify-evenly items-center text-custom-gray">
      {menuItems.map((item) => (
        <div
          key={item.id}
          className={`flex flex-col items-center cursor-pointer gap-1 ${
            activeItem === item.id ? "text-toxic-green" : "text-custom-gray"
          }`}
          onClick={() => handleClick(item.id)}
        >
          {item.icon}
          <p className="text-custom-sm tracking-custom-wide">{item.label}</p>
        </div>
      ))}
    </div>
  );
};
export default Footer;
