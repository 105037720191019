import { FC, useState } from "react";
import css from "./Profile.module.css";
import { ReactComponent as SettingIcon } from "./img/settings-icon.svg";
import { ReactComponent as SupportIcon } from "./img/support-icon.svg";
import { ReactComponent as ChangeIcon } from "./img/change-icon.svg";

import icon from "./img/default-icon.png";
import { getCookie } from "../../helpers/cockieHelper";
import UserCards from "../../components/UserCards/UserCards";
import CopyButton from "../../components/UI/Buttons/CopyButton/CopyButton";
import ProfileButton from "../../components/UI/Buttons/ProfileButton/ProfileButton";
import BackButton from "../../components/UI/Buttons/BackButton/BackButton";
import TextInput from "../../components/UI/Inputs/TextInput";
import Button from "../../components/UI/Buttons/Button/Button";
import PinCodeScreen from "../PinCodeScreen/PinCodeScreen";
import ChangePasswordScreem from "../ChangePasswordScreen/ChangePasswordScreen";

interface ProfileProps {
  setFooterVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Profile: FC<ProfileProps> = ({ setFooterVisible }) => {
  const [window, setWindow] = useState<string>("profile");
  const [name, setName] = useState<string>(getCookie("name") || "");
  const userName = getCookie("name");
  const renderProfile = () => {
    switch (window) {
      case "profile":
        return (
          <div className={css.wrapper}>
            <div className={css.header}>
              <img src={icon} alt="Profile Avatar" className={css.avatar} />
              <p className={css.username}> #{userName}</p>
              <div className={css.setingsWrapper}>
                <div
                  onClick={() => {
                    setWindow("settings");
                  }}
                >
                  <SettingIcon />
                </div>
              </div>
            </div>
            <div className={css.UserCards}>
              <UserCards bonuse="51 560" balance="4 891 672" />
            </div>
            <div className={css.refWrapper}>
              <p className={css.refDescription}>РЕФЕРАЛЬНАЯ ССЫЛКА</p>
              <CopyButton value="ASDSADAS123123VNGNVBN13CB23CCBNCN123NC" />
            </div>

            <ProfileButton Icon={SupportIcon} value={"Поддержка"} />
          </div>
        );
      case "settings":
        setFooterVisible(true);
        return (
          <div className={css.settingsWrapper}>
            <div className={css.arrowWrap}>
              <BackButton goBack={() => setWindow("profile")} />
            </div>
            <p className={css.settingsTitle}>НАСТРОЙКИ</p>
            <div className={css.settingsIconWrap}>
              <img
                src={icon}
                alt="Profile Avatar"
                className={css.settingsAvatar}
              />
              <div className={css.changeIconWrap}>
                <ChangeIcon />
              </div>
            </div>
            <div className={css.buttons}>
              <div className={css.changeNamneWrapper}>
                <p className={css.label}>НИК</p>
                <TextInput
                  placehoder=""
                  value={name}
                  classes={{
                    input: "text-[#7b7b7b]",
                  }}
                  onChange={setName}
                />
              </div>
              <ProfileButton
                value={"Сменить пин"}
                onClick={() => {
                  setFooterVisible(false);
                  setWindow("change_pin");
                }}
              />
              <Button onClick={() => {}}>Сохранить</Button>
            </div>
          </div>
        );
      case "change_pin":
        return (
          <ChangePasswordScreem
            setScreen={() => {
              setWindow("settings");
            }}
          />
        );
    }
  };
  return <>{renderProfile()}</>;
};
export default Profile;
