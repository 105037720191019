import { FC } from "react";
import css from "./TrideCard.module.css";
import cn from "classnames";
import { ReactComponent as CheckMark } from "./icons/chekMark.svg";
import { ReactComponent as EmptyMark } from "./icons/empty.svg";

interface TradeCardProps {
  name: string;
  color: string;
  bothCount: number;
  setMemeScreen: React.Dispatch<React.SetStateAction<string>>;
  description?: string;
  size: string;
}

const TradeCard: FC<TradeCardProps> = ({
  name,
  color,
  bothCount,
  setMemeScreen,
  description,
  size,
}) => {
  return (
    <div
      className={cn(css.wrapper, {
        [css.backgroundBlue]: color === "blue" && size === "smal",
        [css.backgroundRed]: color === "red" && size === "smal",
        [css.backgroundPurple]: color === "purple" && size === "smal",
        [css.backgroundGreen]: color === "green" && size === "smal",
        [css.backgroundBlueBig]: color === "blue" && size === "big",
        [css.backgroundRedBig]: color === "red" && size === "big",
        [css.backgroundPurpleBig]: color === "purple" && size === "big",
        [css.backgroundGreenBig]: color === "green" && size === "big",
        [css.small]: size === "smal",
      })}
      onClick={() => setMemeScreen(color)}
    >
      <h2 className={css.label}>
        ${name}-{+name * 5}
      </h2>

      {description && <p className={css.description}>{description}</p>}

      <div className={css.footerWraper}>
        <span className={css.footer}>ДОСТУПНО К ПОКУПКЕ</span>
        <div
          className={cn(css.labels, {
            [css.markBlue]: color === "blue",
            [css.markRed]: color === "red",
            [css.markPurpule]: color === "purple",
            [css.markGreen]: color === "green",
          })}
        >
          {[...Array(bothCount)].map((_, index) => (
            <CheckMark key={index} />
          ))}
          {[...Array(5 - bothCount)].map((_, index) => (
            <EmptyMark key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TradeCard;
