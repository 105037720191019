import { FC, useEffect, useState, useCallback } from "react";
import css from "./footer.module.css";
import Button from "../button/button.component";
import cn from "classnames";
import LoaderIcon from "../icons/loader-icon.component";
import SuccesIcon from "../icons/succes-icon.component";
import FalledIcon from "../icons/falled-icon.component";
import OverdueIcon from "../icons/overdue-icon.component";
import CountdownTimer from "../countdownTimer/countdownTimer.component";
import { api, apiEcvaring } from "../../../../../api/api";
import { getCookie } from "../../../../../helpers/cockieHelper";

type FooterType = "regular" | "pending" | "success" | "falled" | "overdue";
interface FooterProps {
  setOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  amount: string;
  setAuthorizedScreen: React.Dispatch<React.SetStateAction<string>>;
}
const Footer: FC<FooterProps> = ({
  setOverlay,
  amount,
  setAuthorizedScreen,
}) => {
  const [type, setType] = useState<FooterType>("regular");
  const [paymentId, setPaymentId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const footerClass = cn(css.wrap, {
    [css.regular]: type === "regular",
    [css.big]: type !== "regular",
    [css.pending]: type === "pending",
    [css.success]: type === "success",
    [css.falled]: type === "falled",
    [css.overdue]: type === "overdue",
  });

  const checkPayment = useCallback(async () => {
    setType("regular");
    console.log("sent");
    try {
      const response = await apiEcvaring.post(
        "/payment",
        { amount: amount },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data.paymentId);
      setPaymentId(response.data.paymentId);
      setLoading(true);

      //http://localhost:3500/payment/?=830cb433-0e1d-4226-9623-8d63ab45756f

      //   if (response.data.message === "Оплата успешно прошла") {
      //     setType("success");
      //     try {
      //       const response = await api.post("investments/make-invest/", {
      //         slots: [{ slot_name: amount, quantity: 1 }],
      //       });
      //       console.log(response);
      //       setAuthorizedScreen("memetrade");
      //     } catch (err) {
      //       console.log(err);
      //     }
      //   } else {
      //     setType("falled");
      //   }
    } catch (error) {
      setType("falled");
    }
  }, [amount]);

  useEffect(() => {
    if (!loading || !paymentId) return;
    const intervalId = setInterval(async () => {
      try {
        const response = await apiEcvaring.get(
          `payment?paymentId=${paymentId}`,

          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data.paid === true);
        if (response.data.paid === true) {
          try {
            const body = {
              slots: [
                {
                  slot_name: amount,
                  quantity: 1,
                },
              ],
            };
            const response = await api.post("investments/make-invest/", body, {
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${getCookie("key")?.toString()} `, // Токен из куки
                "Content-Type": "application/json",
              },
            });

            setPaymentId("");
            setLoading(false);
            setAuthorizedScreen("memetrade");
          } catch (error) {
            console.error("error while init invest", error);
          }
        }
      } catch (error) {
        console.error("error while init invest", error);
      }
    }, 4000);
    return () => clearInterval(intervalId);
  }, [paymentId, loading]);

  useEffect(() => {
    if (type !== "regular") {
      setOverlay(true);
    } else {
      setOverlay(false);
    }
  }, [type, setOverlay]);

  useEffect(() => {
    checkPayment();
  }, []);

  const makeInvest = async () => {
    try {
      const body = {
        slots: [
          {
            slot_name: amount,
            quantity: 1,
          },
        ],
      };
      const response = await api.post("investments/make-invest/", body, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getCookie("key")?.toString()} `, // Токен из куки
          "Content-Type": "application/json",
        },
      });

      setAuthorizedScreen("memetrade");
    } catch (error) {
      console.error("error while init invest", error);
    }
  };

  const renderFooterContent = () => {
    switch (type) {
      case "regular":
        return (
          <>
            <div className={css.timeWrap}>
              <span className={css.title}>До закрытия сделки</span>
              <CountdownTimer initialTime={"03:00"} />
            </div>

            {/* <Button
              value="Продолжить"
              onClick={async () => {
                setType("success");
                try {
                  const response = await api.post("investments/make-invest/", {
                    slots: [{ slot_name: amount, quantity: 1 }],
                  });
                  console.log(response);
                  setAuthorizedScreen("memetrade");
                } catch (err) {
                  console.log(err);
                }
              }}
              color="green"
            /> */}
          </>
        );
      case "pending":
        return (
          <>
            <div className={css.loaderWrap}>
              <LoaderIcon />
            </div>
            <span> Ожидаем оплату</span>
          </>
        );
      case "success":
        return (
          <>
            <SuccesIcon />
            <span className={css.description}>Успешная оплата</span>
            <Button
              onClick={() => setType("regular")}
              value={"Вернуться"}
              color={"white"}
            />
          </>
        );
      case "falled":
        return (
          <>
            <FalledIcon />
            <span className={css.description}>Платеж не прошел</span>
            <Button
              onClick={() => checkPayment()}
              value={"Повторить платеж"}
              color={"black"}
            />
          </>
        );
      case "overdue":
        return (
          <>
            <OverdueIcon />
            <span className={css.description}>Истек срок сделки</span>
            <Button
              onClick={() => setType("regular")}
              value={"Повторить платеж"}
              color={"black"}
            />
          </>
        );
      default:
        return null;
    }
  };

  return <footer className={footerClass}>{renderFooterContent()}</footer>;
};
export default Footer;
