import { FC, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Memtrade from "../Memtrade/Memtrade";
import Profile from "../Profile/Profile";
import Main from "./CryptoEcvaringScreen/components/main/main.component";
import MainPage from "./CryptoEcvaringScreen/components/pages/mainPage.component";

const Authorized: FC = () => {
  const [authorizedScreen, setAuthorizedScreen] = useState("memetrade");
  const [amount, setAmount] = useState("0");
  const [wallet, setWallet] = useState("");
  const [isFooterVisible, setFooterVisible] = useState<boolean>(true);
  const [activeItem, setActiveItem] = useState<string>("memetrade");

  const authorizedRender = () => {
    switch (authorizedScreen) {
      case "memetrade":
        return (
          <Memtrade
            setAuthorizedScreen={setAuthorizedScreen}
            setAmount={setAmount}
            setFooterVisible={setFooterVisible}
          />
        );
      case "home":
        return <h1>123</h1>;
      case "game":
        return <h1>123</h1>;
      case "referal":
        return <h1>123</h1>;
      case "profile":
        return <Profile setFooterVisible={setFooterVisible} />;
      case "ecvaring":
        return (
          <MainPage
            wallet={wallet}
            amount={amount}
            setAuthorizedScreen={setAuthorizedScreen}
          />
        );
    }
  };
  return (
    <div>
      {authorizedRender()}
      {isFooterVisible && (
        <Footer
          setAuthorizedScreen={setAuthorizedScreen}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      )}
    </div>
  );
};

export default Authorized;
